import React from 'react';

const Header = ({  title }) => {
  return (
    <section className="inner-header style-5" style={{ background: '#ff0001'}}>
      <div className="container">
        <div className="content" style={{ color: '#fff'}}>                    
          <h2 > { title } </h2>
          <img src="/assets/img/about/style_3_1.png" alt="" className="side-img slide_up_down" />
        </div>
      </div>
    </section>
  )
}

export default Header